import Vue from 'vue'
import Vuex from 'vuex'
import {getAppClient, getAuthClient} from "@/api/clients";
import {gql} from "graphql-request";

Vue.use(Vuex)

export const store =  new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    setUser(state, user){
      //console.log({state, user})
      state.user = user;
    },
    saveToken(_, token){
      localStorage.setItem('token', token)
    }
  },
  actions: {
    signInWithEmailAndPassword(state, { username, password }){
      //console.log({username, password})
      return getAuthClient().post('/login', { username, password }).then( (data) => data.data.access_token )
      /*return new Promise((resolve, reject) => {
        if (email === password) {
          state.commit('setUser', {id: 1, name: "test"})
          resolve({id: 1, name: "test"});
        }
        else {
          state.commit('setUser', null )
          reject("wrong login or password")
        }
      })*/
    },
    async getCurrentUser(){
      return await getAppClient().request(LOAD_USER_QUERY).then( (data) => data.me ).catch(() => null)
    },
    checkAuth({commit}) {
      return store.dispatch('getCurrentUser').then( async (data) => {
        //console.log({data})
        await commit('setUser', data || null)
        return data
      })
    },
    async loadOrders(_, { page = 1, pageLimit = 20, filters = {} }){
      return await getAppClient().request(LOAD_ORDERS_QUERY, { page, pageLimit, filters })
        .then( (data) => ({...data.orders, page}) )
        .catch(() => ({ page, total: 0 }))
    }
  },
  modules: {},
  getters: {
    user(state){
      return state.user;
    }
  }
})


export const LOAD_ORDERS_QUERY = gql`
    query q($page: Int!, $pageLimit: Int!, $filters:OrderFilter){
        orders(pagination: {pageLimit: $pageLimit, page: $page}, filters: $filters){
            orders{
                id
                status
                customer{
                    id
                    guid
                }
                discount
                dividends
                terminal{
                    id
                    name
                    business{
                        id
                        name
                    }
                }
                fullDiscount
                sum
                createdAt
            }
            
            total
        }
    }
`

export const LOAD_USER_QUERY = gql`
    query {
        me{
            id
            phone
            roles{
                name
            }
            profile{
                firstName
                secondName
                email
            }
        }
    }
`
