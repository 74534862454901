import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/',
    name: 'App',
    component: () => import('@/views/NavContainer'),
    redirect: { name: 'Orders' },
    children: [
/*      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home')
      },*/
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About')
      },
      {
        path: '/orders/',
        name: 'Orders',
        redirect: { name: 'OrdersList' },
        component: () => import('@/components/Container'),
        children: [
          {
            path: '',
            name: 'OrdersList',
            component: () => import('@/views/orders/OrdersList')
          },
        ],
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //console.log(store.state.user)
  if (to.name !== 'Login' && !store.state.user) next({ name: 'Login' })
  else next()
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' && store.state.user) next({ name: 'App' })
  else next()
})




export default router



