import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '@/styles/index.css'
import '@/styles/main.scss'
import VueTailwind from 'vue-tailwind'
import {store} from './store'

Vue.config.productionTip = false



const components = {

}

Vue.use(VueTailwind, components)



store.dispatch('checkAuth').then(() => {
  // whatever code you use to first initialise your router, add it in here, for example
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

// GOOD
