import { GraphQLClient } from 'graphql-request'
import axios from "axios";

export function getAppClient(){
  return new GraphQLClient('https://partnersapi.partner24.app/graphql', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  })
}

export function getAuthClient(){
  return axios.create({
    baseURL: "https://authapi.partner24.app/"
  })
}




